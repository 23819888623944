import {useContext, useEffect, useState} from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import {Button, FormControl, MenuItem, OutlinedInput, Select, TextField, Typography} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import SaveIcon from "@mui/icons-material/Save";
import {GlobalDataContext} from "./GlobalDataProvider";
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {csCZ} from "@mui/x-date-pickers/locales";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import Grid from "@mui/material/Grid2";
import {useAuthHeader} from "react-auth-kit";
import axios from "axios";
import toast from "react-hot-toast";

const dayjs = require('dayjs');

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '95%',
  maxWidth: '70vh',
  maxHeight: '90vh',
  overflowY: 'auto',
  boxShadow: 24,
  bgcolor: 'background.paper',
  p: "0px 40px 30px 40px",
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    },
  },
};

const ContractEdit = ({mode, selectedContract, opened, data, handleClose, updateRows}) => {
  const {listOfCentres} = useContext(GlobalDataContext);
  const [contract, setContract] = useState({
    code: "",
    description: "",
    costCenter: "",
    scope: "",
    costAssumption: "",
    startDate: null,
    endDate: null,
    contractNumber: null, //FE not implemented
    status: null, //FE not implemented
    selectedGroup: "",
    selectedPhases: [],
    selectedSource: ""
  });
  const [sourceList, setSourceList] = useState([]);
  const [groupList, setGroupList] = useState([]);
  const [phaseList, setPhaseList] = useState([]);
  const getAuthHeader = useAuthHeader();

  useEffect(() => {
    if (opened) {
      mode === "create" && fetchLabels();
      mode === "update" && fetchUpdateData();
    }else {
     clearForm();
    }
  }, [opened]);

  const fetchUpdateData = async () => {
    try {
      setContract({
        code: selectedContract.code,
        description: selectedContract.description,
        costCenter: selectedContract.costCenter.toString(),
        scope: roundNumber(selectedContract.scope),
        costAssumption: roundNumber(selectedContract.costAssumption),
        startDate: removeTimezone(selectedContract.startDate),
        endDate: removeTimezone(selectedContract.endDate),
        selectedGroup: selectedContract.groupList[0],
        selectedPhases: selectedContract.phaseList,
        selectedSource: selectedContract.sourceList[0]
      });
      fetchLabels();
    } catch (error) {
      console.log(error);
    }
  };

  const fetchLabels = async () => {
    try {
      data.report.labelGroupList.forEach(label => {
        if (label.group) setGroupList(label.labelList);
        if (label.phase) setPhaseList(label.labelList);
        if (label.source) setSourceList(label.labelList);
      });
    } catch (error) {
      console.log(error);
    }
  };

  const clearForm = () => {
    setContract({
      code: "",
      description: "",
      costCenter: "",
      scope: "",
      costAssumption: "",
      startDate: null,
      endDate: null,
      selectedGroup: "",
      selectedPhases: [],
      selectedSource: ""
    });
    setSourceList([]);
    setGroupList([]);
    setPhaseList([]);
  };

  const handleInputChange = (field, value) => {
    setContract(prev => ({...prev, [field]: value}));
  };

  const handleSave = async () => {
    try {
      const token = getAuthHeader();
      await axios.post(
        `https://flexioverview.upi-group.cz/contract/${mode}`,
        {
          ...contract,
          companyCode: data.report.companyDetail.code,
        },
        {headers: {Authorization: token}}
      );
      toast.success(`Zakázka byla ${mode === "create" ? "vytvořena" : "upravená"}!`);
      const newRow = {
        id: contract.code,
        ...contract,

        // newCostAssumption: newCostAssumption,
        // newScope: newScope,
        // realized: realized,
        // remaining: newScope - realized,

      }
      updateRows(newRow);
      handleClose();
    } catch (error) {
      console.log(error);
      toast.error(`Zakázku se nepodařilo ${mode === "create" ? "vytvořit" : "upravit"}!`);
    }
  };

  function roundNumber(number) {
    if (number === null || number === '' || isNaN(number)) {
      return '';
    }
    let roundedNumber = Math.round(number); // Round to the nearest whole number
    return roundedNumber.toLocaleString('cs-CZ'); // Format using locale without decimal places
  }

  function removeTimezone(dateString) {
    const plusIndex = dateString.indexOf('+');
    if (plusIndex !== -1) {
      return dateString.slice(0, plusIndex);
    }
    return dateString;
  }


  return (
    <Modal
      open={opened}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography variant="h5" sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          paddingTop: '20px',
          paddingBottom: '20px',
          fontWeight: 'bold',
        }}>
          {mode === "update" ? "Úprava zakázky" : "Vytváření nové zakázky"}{}
        </Typography>
        <Grid container spacing={2} justifyContent="space-around">
          <Grid size={6}>
            Zkratka
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              value={contract.code}
              onChange={(e) => handleInputChange("code", e.target.value)}
            />
          </Grid>
          <Grid size={6}>
            Název:
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              value={contract.description}
              onChange={(e) => handleInputChange("description", e.target.value)}
            />
          </Grid>
          <Grid size={6}>
            Rozsah zakázky:
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              value={contract.scope}
              onChange={(e) => handleInputChange("scope", e.target.value)}
            />
          </Grid>
          <Grid size={6}>
            Předpokládané náklady:
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              value={contract.costAssumption}
              onChange={(e) => handleInputChange("costAssumption", e.target.value)}
            />
          </Grid>
          <Grid size={6}>
            Datum zahájení:
            <LocalizationProvider adapterLocale="cs" dateAdapter={AdapterDayjs}
                                  localeText={csCZ.components.MuiLocalizationProvider.defaultProps.localeText}>
              <DatePicker views={['year', 'month', 'day']} slotProps={{textField: {size: 'small'}}} sx={{width: "100%"}}
                          onChange={(newValue) => handleInputChange("startDate", newValue)}
                          value={contract.startDate != null ? dayjs(contract.startDate) : null}/>
            </LocalizationProvider>
          </Grid>
          <Grid size={6}>
            Datum ukončení:
            <LocalizationProvider adapterLocale="cs" dateAdapter={AdapterDayjs}
                                  localeText={csCZ.components.MuiLocalizationProvider.defaultProps.localeText}>
              <DatePicker views={['year', 'month', 'day']} slotProps={{textField: {size: 'small'}}} sx={{width: "100%"}}
                          onChange={(newValue) => handleInputChange("endDate", newValue)}
                          value={contract.endDate != null ? dayjs(contract.endDate) : null}/>
            </LocalizationProvider>
          </Grid>
          <Grid size={6}>
            Středisko:
            <br/>
            <FormControl sx={{minWidth: "100%"}}>
              <Select
                value={contract.costCenter}
                onChange={(e) => handleInputChange("costCenter", e.target.value)}
                displayEmpty
                size="small"
                inputProps={{'aria-label': 'Without label'}}
              >
                {listOfCentres.map((centre) => (
                  <MenuItem key={centre.id} value={centre.id}>{centre.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid size={6}>
            Skupina:
            <br/>
            <FormControl sx={{minWidth: "100%"}}>
              <Select
                value={contract.selectedGroup}
                onChange={(e) => handleInputChange("selectedGroup", e.target.value)}
                displayEmpty
                size="small"
                inputProps={{'aria-label': 'Without label'}}
              >
                {groupList.map((group) => (
                  <MenuItem key={group} value={group}>{group}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid size={6}>
            Fáze:
            <br/>
            <Select
              labelId="demo-multiple-name-label"
              id="demo-multiple-name"
              multiple
              value={contract.selectedPhases}
              onChange={(e) => handleInputChange("selectedPhases", e.target.value)}
              input={<OutlinedInput/>}
              MenuProps={MenuProps}
              sx={{width: "100%"}}
              size="small"
            >
              {phaseList.map((phase) => (
                <MenuItem
                  key={phase}
                  value={phase}
                  //style={getStyles(name, personName, theme)}
                >
                  {phase}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid size={6}>
            Zdroj:
            <br/>
            <FormControl sx={{minWidth: "100%"}}>
              <Select
                value={contract.selectedSource}
                onChange={(e) => handleInputChange("selectedSource", e.target.value)}
                displayEmpty
                size="small"
                inputProps={{'aria-label': 'Without label'}}
              >
                {sourceList.map((source) => (
                  <MenuItem key={source} value={source}>{source}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid container sx={{paddingTop: '20px'}} spacing={5}>
            <Grid size={6} sx={{display: 'flex', justifyContent: 'end'}}>
              <Button variant="outlined" onClick={handleClose} startIcon={<CloseIcon/>}>
                Zrušit
              </Button>
            </Grid>
            <Grid size={6} sx={{display: 'flex', justifyContent: 'start'}}>
              <Button variant="contained" onClick={handleSave}
                      endIcon={<SaveIcon/>}>
                Uložit
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default ContractEdit;
