import {React, useEffect, useState} from 'react';
import {DataGridPro} from "@mui/x-data-grid-pro";
import {contractsColumns} from "../data/DatatableColumns";
import Typography from "@mui/material/Typography";
import {Button, Paper} from "@mui/material";
import {csCZ} from "@mui/x-data-grid/locales";
import ContractEdit from "./ContractEdit";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Grid from "@mui/material/Grid2";
import axios from "axios";
import toast from "react-hot-toast";
import {useAuthHeader} from "react-auth-kit";


  function Contracts({data}) {
    const [rows, setRows] = useState([]);
    const [selectedContract, setSelectedContract] = useState(null);
    const [popUpOpened, setPopUpOpened] = useState(false);
    const [popUpMode, setPopUpMode] = useState("");
    const getAuthHeader = useAuthHeader();

    useEffect(() => {
      if (data && data.report) generateRows()
    }, [data]);


    const generateRows = () => {
      let rows = [];
      data.report.contractList.forEach((contract) => {

        let newCostAssumption = 0;
        let newScope = 0;
        let realized = 0;

        contract.userValueList.forEach((userValue) => {
          userValue.businessMonthAssumptionList.forEach((assumption) => {
            newCostAssumption += assumption.value || 0;
          })

          userValue.businessMonthScopeList.forEach((scope) => {
            newScope += scope.value || 0;
          })
        })

        data.report.receivedInvoiceList.forEach((invoice) => {
          if (invoice.contract === contract.code) {
            realized += invoice.actualPayment;
          }
        })
        rows.push({
          id: contract.code,
          code: contract.code,
          description: contract.description,
          phases: contract.phaseList.map(phase => phase).join(", "),
          status: contract.status,
          contractNumber: contract.contractNumber,
          startDate: contract.startDate,
          endDate: contract.endDate,
          costAssumption: contract.costAssumption,
          scope: contract.scope,
          newCostAssumption: newCostAssumption,
          newScope: newScope,
          realized: realized,
          remaining: newScope - realized,
        })
        setRows(rows);
      })
    }

    const updateRows = (newRow) => {
      setRows(prevRows => [...prevRows, newRow]);
    };

    const deleteRow = (rowId) => {
      setRows(prevRows => prevRows.filter(row => row.id !== rowId));
    };
  const handleUpdateClick = (idPrefix) => {
    const selectedContract = data.report.contractList.find(row => row.code.startsWith(idPrefix));
    if (selectedContract) {
      setPopUpMode("update");
      setSelectedContract(selectedContract);
      handlePopUpOpen();
    } else {
      console.error("No matching contract found");
    }
  };

    const handleDeleteClick = async (idPrefix) => {
      const selectedContract = data.report.contractList.find(row => row.code.startsWith(idPrefix));
      try {
        const token = getAuthHeader();
        await axios.post(
          `https://flexioverview.upi-group.cz/contract/delete`,
          {
            companyCode: data.report.companyDetail.code,
            code: selectedContract.code
          },
          {headers: {Authorization: token}}
        );
        toast.success(`Zakázka byla smazána!`);
        deleteRow(selectedContract.code);
      } catch (error) {
        console.log(error);
        toast.error(`Zakázku se nepodařilo smazat!`);
      }
    };

  const handleAddClick = () => {
    setPopUpMode("create");
    setSelectedContract(null);
    handlePopUpOpen();
  }

  const handlePopUpOpen = () => {
    setPopUpOpened(true);
  }
  const handlePopUpClose = () => {
    setPopUpOpened(false);
    setSelectedContract(null);
  }

  return (
    <Paper>
      <Grid container>
        <Grid size={6}>
          <Typography
            sx={{
              paddingLeft: 2,
              paddingTop: 1.7,
              paddingBottom: 1.7,
              fontWeight: 'bold',
              backgroundColor: 'white',
              position: 'sticky',
              left: 0,
              fontSize: "0.85rem",
              height: '45px',
            }}
          >
            Zakázky
          </Typography>
        </Grid>
        <Grid size={6} alignItems="end" sx={{alignItems: "center", justifyContent: "end", display: "flex", paddingRight: 2}} >
          <Button variant="contained" color="success" onClick={handleAddClick} sx={{ fontSize: "14", padding: 0.5, paddingLeft: 2, paddingRight: 2}} startIcon={<AddCircleIcon/>}>
            Přidat
          </Button>
        </Grid>
      </Grid>
      <DataGridPro
        columns={contractsColumns(handleUpdateClick, handleDeleteClick)}
        rows={rows}
        hideFooter
        localeText={csCZ.components.MuiDataGrid.defaultProps.localeText}
        getCellClassName={(params) => {
          if (params.value < 0) {
            return 'negative'
          }
        }}
        sx={{
          height: "77vh",
          '&.MuiDataGrid-root': {
            borderRadius: '0px',
            borderLeft: 'none',
            borderRight: 'none',
          },
          '& .MuiDataGrid-columnHeaderTitle': {
            fontWeight: 'bold', overflow: 'visible'
          },
          '& .negative': {
            color: 'red'
          }

        }}/>
      <ContractEdit mode={popUpMode} data={data} selectedContract={selectedContract} opened={popUpOpened} handleClose={handlePopUpClose} updateRows={updateRows}/>
    </Paper>
  );
}

export default Contracts;